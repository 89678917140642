<template>
<div class="is-flex is-flex-direction-column">
  <video id='qrscanvideo'></video>
  {{ cameras && cameras[preferredCamera] && cameras[preferredCamera].label }}
</div>

</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref, watch } from '@vue/runtime-core';
import QrScanner from 'qr-scanner'; 
import { useStore } from 'vuex';

export default {
  name: "QRCodeScanner",
  props: {
    switchCameraTrigger: {
      type: Number,
      default: 0
    },
    switchFlashTrigger:{
      type: Number,
      default: 0
    }
  },
  emits: ['result'],
  components: {
   // MiniStack
   },
  setup(props,context) {


    const store = useStore();
    const preferredCamera = ref(0);
    const cameras = ref([]);
    let qrScanner;

    function onDecodeError(e){
      return e
    }

    let keyinput = "";

    function keylistener(e){
        if (e.key == "Enter"){
          onScanSuccess(keyinput,{keyinput});
          keyinput = "";
          return
        }
        keyinput += e.key
    }

    onMounted(async ()=>{
      keyinput = "";
      document.addEventListener("keypress",keylistener)

      cameras.value = await QrScanner.listCameras(); // async; without requesting camera labels
      console.log("cameras",cameras);

      qrScanner = new QrScanner(
        document.getElementById('qrscanvideo'),
        result => onScanSuccess(result.data,result),
        { onDecodeError,preferredCamera:'environment', highlightCodeOutline:true,highlightScanRegion:true },
      );

      qrScanner.start()

    })

    onBeforeUnmount(()=>{
      document.removeEventListener("keypress",keylistener);
    })

    watch(computed(()=>props.switchCameraTrigger),()=>{switchCamera()})
    watch(computed(()=>props.switchFlashTrigger),()=>{toggleFlash()})

    let lastCameraSwitch = Date.now()
    const switchCamera = ()=>{
      if (Date.now() < lastCameraSwitch + 1000) return console.log("to fast switch. omitting")
      lastCameraSwitch = Date.now();
      preferredCamera.value = preferredCamera.value + 1;
      if (preferredCamera.value > cameras.value.length -1) preferredCamera.value = 0;
      console.log("switching camera to",preferredCamera);
      qrScanner.setCamera(cameras.value[preferredCamera.value].id)
    }

    const toggleFlash = async()=>{
      console.log("toggling flash light");
      if (await qrScanner.hasFlash()){
        qrScanner.toggleFlash();
      }
      else{
        console.log("has no flash");
        store.dispatch('triggerToast',{message:"Sorry. 😬 This device does not have a flash light."})
      }
    }

    function onScanSuccess (decodedText, decodedResult) {
      context.emit('result', decodedText, decodedResult);
    }

      return {onScanSuccess,switchCamera,cameras,preferredCamera,toggleFlash};
  },
}
</script>
